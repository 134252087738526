import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/phorkit/phorkit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Flex } from 'components/Flex';
import { Looper } from 'components/Looper';
import { Rhythm } from 'components/Rhythm';
import { PageTitle } from 'docs/helpers/PageTitle';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { FormComponentDemo } from '../../docs/helpers/FormComponentDemo';
import { Toggle, NotifiedToggle } from '../index';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageTitle src="components/Form/Toggle" title="Toggle" mdxType="PageTitle" />
    <h2 {...{
      "id": "basic-toggle"
    }}>{`Basic toggle`}</h2>
    <Playground __position={1} __code={'<ThemeWrapper>\n  <Flex direction=\"column\">\n    <Rhythm my={2}>\n      <Toggle\n        checked\n        aria-label=\"Super fantastic label\"\n        id=\"demo-checked-toggle\"\n        onChange={Function.prototype}\n      />\n      <Toggle\n        aria-label=\"Super fantastic label\"\n        id=\"demo-unchecked-toggle\"\n        onChange={Function.prototype}\n      />\n    </Rhythm>\n  </Flex>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Toggle,
      NotifiedToggle,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Flex direction="column" mdxType="Flex">
      <Rhythm my={2} mdxType="Rhythm">
        <Toggle checked aria-label="Super fantastic label" id="demo-checked-toggle" onChange={Function.prototype} mdxType="Toggle" />
        <Toggle aria-label="Super fantastic label" id="demo-unchecked-toggle" onChange={Function.prototype} mdxType="Toggle" />
      </Rhythm>
    </Flex>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "labeled-toggle"
    }}>{`Labeled toggle`}</h2>
    <Playground __position={2} __code={'<ThemeWrapper>\n  <Rhythm py={3}>\n    <FormComponentDemo\n      unwrapped\n      initialValue={true}\n      property=\"checked\"\n      type=\"toggle\"\n    >\n      <Toggle\n        id=\"demo-labeled-toggle\"\n        onChange={(event, checked) => console.log(checked)}\n      >\n        Super fantastic label\n      </Toggle>\n    </FormComponentDemo>\n    <FormComponentDemo\n      unwrapped\n      initialValue={true}\n      property=\"checked\"\n      type=\"toggle\"\n    >\n      <Toggle\n        reverse\n        id=\"demo-labeled-reverse-toggle\"\n        onChange={(event, checked) => console.log(checked)}\n      >\n        Super fantastic label\n      </Toggle>\n    </FormComponentDemo>\n  </Rhythm>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Toggle,
      NotifiedToggle,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Rhythm py={3} mdxType="Rhythm">
      <FormComponentDemo unwrapped initialValue={true} property="checked" type="toggle" mdxType="FormComponentDemo">
        <Toggle id="demo-labeled-toggle" onChange={(event, checked) => console.log(checked)} mdxType="Toggle">
          Super fantastic label
        </Toggle>
      </FormComponentDemo>
      <FormComponentDemo unwrapped initialValue={true} property="checked" type="toggle" mdxType="FormComponentDemo">
        <Toggle reverse id="demo-labeled-reverse-toggle" onChange={(event, checked) => console.log(checked)} mdxType="Toggle">
          Super fantastic label
        </Toggle>
      </FormComponentDemo>
    </Rhythm>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "full-width-labeled-toggle"
    }}>{`Full width labeled toggle`}</h2>
    <Playground __position={3} __code={'<ThemeWrapper>\n  <Rhythm py={3}>\n    <FormComponentDemo\n      unwrapped\n      initialValue={true}\n      property=\"checked\"\n      style={{ width: 240 }}\n      type=\"toggle\"\n    >\n      <Toggle\n        full\n        id=\"demo-labeled-full-toggle\"\n        onChange={(event, checked) => console.log(checked)}\n      >\n        Super fantastic label\n      </Toggle>\n    </FormComponentDemo>\n    <FormComponentDemo\n      unwrapped\n      initialValue={true}\n      property=\"checked\"\n      style={{ width: 240 }}\n      type=\"toggle\"\n    >\n      <Toggle\n        full\n        reverse\n        id=\"demo-labeled-reverse-fulltoggle\"\n        onChange={(event, checked) => console.log(checked)}\n      >\n        Super fantastic label\n      </Toggle>\n    </FormComponentDemo>\n  </Rhythm>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Toggle,
      NotifiedToggle,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Rhythm py={3} mdxType="Rhythm">
      <FormComponentDemo unwrapped initialValue={true} property="checked" style={{
            width: 240
          }} type="toggle" mdxType="FormComponentDemo">
        <Toggle full id="demo-labeled-full-toggle" onChange={(event, checked) => console.log(checked)} mdxType="Toggle">
          Super fantastic label
        </Toggle>
      </FormComponentDemo>
      <FormComponentDemo unwrapped initialValue={true} property="checked" style={{
            width: 240
          }} type="toggle" mdxType="FormComponentDemo">
        <Toggle full reverse id="demo-labeled-reverse-fulltoggle" onChange={(event, checked) => console.log(checked)} mdxType="Toggle">
          Super fantastic label
        </Toggle>
      </FormComponentDemo>
    </Rhythm>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "wrapped-labeled-toggle"
    }}>{`Wrapped labeled toggle`}</h2>
    <Playground __position={4} __code={'<FormComponentDemo initialValue={true} property=\"checked\" type=\"toggle\">\n  <Toggle\n    id=\"demo-wrapped-labeled-toggle\"\n    onChange={(event, checked) => console.log(checked)}\n  >\n    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer eget dui\n    eu orci ultrices maximus eu eu ante. Vestibulum ligula velit, scelerisque\n    vitae venenatis tempor, consectetur a orci. Aenean molestie eu tortor in\n    pharetra. Nulla lobortis, sapien quis ornare venenatis, nunc libero\n    finibus justo, non tempor nisi urna quis justo. Integer sit amet venenatis\n    leo.\n  </Toggle>\n</FormComponentDemo>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Toggle,
      NotifiedToggle,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <FormComponentDemo initialValue={true} property="checked" type="toggle" mdxType="FormComponentDemo">
    <Toggle id="demo-wrapped-labeled-toggle" onChange={(event, checked) => console.log(checked)} mdxType="Toggle">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer eget dui eu orci ultrices maximus eu eu ante.
      Vestibulum ligula velit, scelerisque vitae venenatis tempor, consectetur a orci. Aenean molestie eu tortor in
      pharetra. Nulla lobortis, sapien quis ornare venenatis, nunc libero finibus justo, non tempor nisi urna quis
      justo. Integer sit amet venenatis leo.
    </Toggle>
  </FormComponentDemo>
    </Playground>
    <h2 {...{
      "id": "disabled-toggle"
    }}>{`Disabled toggle`}</h2>
    <Playground __position={5} __code={'<Toggle checked disabled id=\"demo-disabled=checked-toggle\">\n  Super fantastic label\n</Toggle>\n<br />\n<br />\n<Toggle disabled id=\"demo-disabled-unchecked-toggle\">\n  Super fantastic label\n</Toggle>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Toggle,
      NotifiedToggle,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Toggle checked disabled id="demo-disabled=checked-toggle" mdxType="Toggle">
    Super fantastic label
  </Toggle>
  <br />
  <br />
  <Toggle disabled id="demo-disabled-unchecked-toggle" mdxType="Toggle">
    Super fantastic label
  </Toggle>
    </Playground>
    <h2 {...{
      "id": "small-toggle"
    }}>{`Small toggle`}</h2>
    <Playground __position={6} __code={'<FormComponentDemo initialValue={true} property=\"checked\" type=\"toggle\">\n  <Toggle\n    id=\"demo-small-toggle\"\n    onChange={(event, checked) => console.log(checked)}\n    size=\"small\"\n  >\n    Super fantastic label\n  </Toggle>\n</FormComponentDemo>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Toggle,
      NotifiedToggle,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <FormComponentDemo initialValue={true} property="checked" type="toggle" mdxType="FormComponentDemo">
    <Toggle id="demo-small-toggle" onChange={(event, checked) => console.log(checked)} size="small" mdxType="Toggle">
      Super fantastic label
    </Toggle>
  </FormComponentDemo>
    </Playground>
    <h2 {...{
      "id": "contrast-toggles"
    }}>{`Contrast toggles`}</h2>
    <Playground __position={7} __code={'<ThemeWrapper contrast>\n  <Flex direction=\"column\">\n    <Rhythm my={2}>\n      <Toggle\n        checked\n        contrast\n        id=\"demo-checked-contrast-toggle\"\n        onChange={Function.prototype}\n      >\n        Super fantastic label\n      </Toggle>\n      <Toggle\n        contrast\n        id=\"demo-unchecked-contrast-toggle\"\n        onChange={Function.prototype}\n      >\n        Super fantastic label\n      </Toggle>\n    </Rhythm>\n  </Flex>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Toggle,
      NotifiedToggle,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper contrast mdxType="ThemeWrapper">
    <Flex direction="column" mdxType="Flex">
      <Rhythm my={2} mdxType="Rhythm">
        <Toggle checked contrast id="demo-checked-contrast-toggle" onChange={Function.prototype} mdxType="Toggle">
          Super fantastic label
        </Toggle>
        <Toggle contrast id="demo-unchecked-contrast-toggle" onChange={Function.prototype} mdxType="Toggle">
          Super fantastic label
        </Toggle>
      </Rhythm>
    </Flex>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "notifications"
    }}>{`Notifications`}</h2>
    <Playground __position={8} __code={'<ThemeWrapper>\n  <Looper\n    list={[\'danger\', \'warning\', \'success\', \'primary\', \'neutral\']}\n    render={level => (\n      <Rhythm py={3}>\n        <FormComponentDemo\n          unwrapped\n          initialValue={true}\n          property=\"checked\"\n          type=\"toggle\"\n        >\n          <NotifiedToggle\n            level={level}\n            notification={`This is an example ${level} notification.`}\n            onChange={(event, checked) => console.log(checked)}\n          >\n            Super fantastic label\n          </NotifiedToggle>\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Toggle,
      NotifiedToggle,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={['danger', 'warning', 'success', 'primary', 'neutral']} render={level => <Rhythm py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped initialValue={true} property="checked" type="toggle" mdxType="FormComponentDemo">
            <NotifiedToggle level={level} notification={`This is an example ${level} notification.`} onChange={(event, checked) => console.log(checked)} mdxType="NotifiedToggle">
              Super fantastic label
            </NotifiedToggle>
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`[`}{`props`}{`]`}</h2>
    <Props of={Toggle} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      